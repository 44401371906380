<template>
  <div>
    <div class="">
      <mdb-row>
        <mdb-col sm="12" md="12" lg="12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-end flex-wrap">
                <div>
                  <mdb-btn
                    icon="download"
                    tag="a"
                    size="sm"
                    color="primary"
                    class="m-0"
                    :disabled="exportBtn"
                    @click="exportReport('xlsx')"
                  ></mdb-btn>
                  <mdb-dropdown btn-group split dropleft>
                    <mdb-dropdown-toggle
                      :disabled="exportBtn"
                      slot="toggle"
                      color="primary"
                      size="sm"
                      class="px-2 py-2"
                    ></mdb-dropdown-toggle>
                    <mdb-dropdown-menu>
                      <mdb-dropdown-item @click="exportReport('xlsx')"
                        ><i class="fas fa-download"></i> Exporter en
                        XLSX</mdb-dropdown-item
                      >
                      <mdb-dropdown-item @click="exportReport('csv')"
                        ><i class="fas fa-download"></i> Exporter en
                        CSV</mdb-dropdown-item
                      >
                    </mdb-dropdown-menu>
                  </mdb-dropdown>
                </div>
              </div>
              <form class="form" @submit.prevent="loadReports">
                <mdb-row>
                  <mdb-col sm="12" md="6" lg="2">
                    <mdb-date-picker-2
                      :options="option"
                      :validation="$store.state.report.errors.start ? true : false"
                      :invalidFeedback="$store.state.report.errors.start"
                      v-model="form.start_date"
                      v-mask="'####-##-##'"
                      autoHide
                      outline
                      label="Date Début"
                      title="Date Début"
                    />
                  </mdb-col>
                  <mdb-col sm="12" md="6" lg="2">
                    <mdb-date-picker-2
                      :options="option"
                      :validation="$store.state.report.errors.end ? true : false"
                      :invalidFeedback="$store.state.report.errors.end"
                      v-model="form.end_date"
                      v-mask="'####-##-##'"
                      autoHide
                      outline
                      label="Date Fin"
                      title="Date Fin"
                    />
                  </mdb-col>
                  <mdb-col
                    sm="12"
                    md="6"
                    lg="3"
                    class=""
                    v-if="$gate.checkTypeRole(['super-admin', 'admin'])"
                  >
                    <mdb-select
                      flipOnScroll
                      class="mb-n3"
                      @getValue="getSite"
                      multiple
                      selectAll
                      selectAllPlaceholder="Tout selectionner"
                      v-model="siteOption"
                      outline
                      label="Sites"
                    ></mdb-select>
                    <div
                      style="color: #dc3545; font-size: 0.8rem"
                      v-if="$store.state.report.errors.sites"
                    >
                      {{ $store.state.report.errors.sites }}
                    </div>
                  </mdb-col>
                  <mdb-col
                    sm="12"
                    md="6"
                    lg="3"
                    class=""
                    v-if="$gate.checkTypeRole(['super-admin', 'admin'])"
                  >
                    <mdb-select
                      flipOnScroll
                      class="mb-n3"
                      @getValue="getUser"
                      multiple
                      selectAll
                      selectAllPlaceholder="Tout selectionner"
                      v-model="userOption"
                      outline
                      label="Factureurs"
                    ></mdb-select>
                    <div
                      style="color: #dc3545; font-size: 0.8rem"
                      v-if="$store.state.report.errors.users"
                    >
                      {{ $store.state.report.errors.users }}
                    </div>
                  </mdb-col>
                  <mdb-col sm="12" md="6" lg="2" class="d-flex align-items-center">
                    <button type="submit" class="btn btn-primary btn-md">
                      Rechercher
                    </button>
                  </mdb-col>
                </mdb-row>
              </form>
              <div class="content mt-4" v-loading="loading">
                <mdb-tbl responsive>
                  <mdb-tbl-head>
                    <tr>
                      <th>N° Facture</th>
                      <th class="text-left">Date</th>
                      <th>Client</th>
                      <th class="text-left">Vendeur</th>
                      <th class="text-right">HT</th>
                      <th class="text-right">Tax Spécifique</th>
                      <th class="text-right">TVA</th>
                      <th class="text-right">T1</th>
                      <th class="text-right">T2</th>
                      <th class="text-right">AIB</th>
                      <th class="text-right">Montant TTC</th>
                    </tr>
                  </mdb-tbl-head>
                  <mdb-tbl-body>
                    <tr v-for="report in queriedData" :key="report.id">
                      <td>{{ report.num_facture }}</td>
                      <td>{{ report.date_facture }}</td>
                      <td>{{ report.name_customer }}</td>
                      <td>{{ report.seller }}</td>
                      <td class="text-right">{{ report.mht | formatNumber }}</td>
                      <td class="text-right">{{ report.mts | formatNumber }}</td>
                      <td class="text-right">{{ report.mtva | formatNumber }}</td>
                      <td class="text-right">{{ report.t1 | formatNumber }}</td>
                      <td class="text-right">{{ report.t2 | formatNumber }}</td>
                      <td class="text-right">{{ report.maib | formatNumber }}</td>
                      <td class="text-right">{{ report.mttc | formatNumber }}</td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </div>
              <div class="d-flex justify-content-end">
                <tec-pagination
                  v-model="dataPagination.pagination.currentPage"
                  :per-page="parseFloat(dataPagination.pagination.perPage)"
                  :total="dataPagination.pagination.total"
                ></tec-pagination>
              </div>
            </div>
          </div>
        </mdb-col>
      </mdb-row>
    </div>
  </div>
</template>

<script>
import TecPagination from "@/tba/components/Pagination";
import download from "../../../../services/download-fetch";
import {
  mdbRow,
  mdbCol,
  mdbDatePicker2,
  mdbSelect,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbBtn,
} from "mdbvue";
export default {
  components: {
    mdbSelect,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbDatePicker2,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    TecPagination,
  },
  data() {
    const now = new Date();
    const first = now.getFullYear() + "-" + "01" + "-" + "01";
    const last = now.getFullYear() + "-" + "12" + "-" + "31";

    return {
      option: {
        week: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        month: [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        placeholder: "choisir une date",
        buttons: {
          ok: "Ok",
          clear: "Effacer",
          cancel: "annuler",
          today: "Aujourd hui",
        },
      },
      sites: [],
      siteOption: [],
      users: [],
      userOption: [],
      reports: [],
      loading: false,
      exportBtn: false,
      form: {
        start_date: first,
        end_date: last,
        users: [],
        sites: [],
      },

      dataPagination: {
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [
            { text: "10", value: "10", selected: true },
            { text: "25", value: "25" },
            { text: "50", value: "50" },
            { text: "100", value: "100" },
          ],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: [""],
      },

      data: {
        rows: [],
      },
    };
  },
  methods: {
    async exportReport(type = "xlsx") {
      this.exportBtn = !this.exportBtn;
      await download({
        url: "/reports/invoices?export=" + type,
        method: "POST",
        data: this.form,
        isTh: 0,
        filename: "factures" + "." + type,
      })
        .then(() => {
          this.exportBtn = !this.exportBtn;
          this.$notify({
                 
            message: "Rapport Généré avec succès",
            type: "success",
          });
        })
        .catch(() => {
          this.exportBtn = !this.exportBtn;
          this.$notify({
              
            message: "Echec durant l'exportation, reessayer ulterieurement",
            type: "danger",
          });
        });
    },

    getSite(value) {
      this.form.sites = value;
    },

    getUser(value) {
      this.form.users = value;
    },

    async loadSites() {
      this.$nprogress.start();
      await this.$store
        .dispatch("site/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.sites = response.data.sites;

          this.sites.forEach((site) => {
            this.siteOption.push({
              text: site.name,
              value: site.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async loadUsers() {
      this.$nprogress.start();
      await this.$store
        .dispatch("user/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.users = response.data.users;

          if (this.$gate.checkTypeRole(["super-admin", "admin"])) {
            this.userOption.push({
              text:
                this.$store.state.auth.user.firstname +
                " " +
                this.$store.state.auth.user.lastname,
              value: this.$store.state.auth.user.id,
            });
          }

          this.users.forEach((user) => {
            this.userOption.push({
              text: user.firstname + " " + user.lastname,
              value: user.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    loadReports() {
      this.$nprogress.start();
      this.loading = !this.loading;
      this.$store.commit("report/SET_CLEAN");
      this.$store
        .dispatch("report/invoiceReports", this.form)
        .then((response) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.data.rows = response.data.invoices;
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          if (error.response.data.errors) {
            this.$store.commit("report/SET_START", error.response.data.errors.from);
            this.$store.commit("report/SET_END", error.response.data.errors.to);
            this.$store.commit("report/SET_SITES", error.response.data.errors.sites);
            this.$store.commit("report/SET_USERS", error.response.data.errors.users);
          }
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    // Systeme de datatable
    totalCalcul() {
      return (this.dataPagination.pagination.total = this.data.rows.length);
    },

    searchQ() {
      if (!this.dataPagination.searchQuery) {
        // return this.pagedData;
        return new Error("Nothing To Search");
      }

      let result = this.data.rows.filter((row) => {
        let isIncluded = false;
        for (let key of this.dataPagination.propsToSearch) {
          let rowValue = "";
          rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.dataPagination.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },

    nbrPerPageSelected(value) {
      let nbr = value;
      return this.nbrPerPage(nbr);
    },

    nbrPerPage(nbr) {
      return (this.dataPagination.pagination.perPage = nbr);
    },
  },
  created() {
    this.loadSites();
    this.loadUsers();
    // this.loadReports();
  },
  computed: {
    pageData() {
      return this.data.rows.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.dataPagination.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    queriedData() {
      let result = this.searchQ();
      return result instanceof Error ? this.pageData : result;
    },

    from() {
      return (
        this.dataPagination.pagination.perPage *
        (this.dataPagination.pagination.currentPage - 1)
      );
    },

    total() {
      return this.totalCalcul();
    },
  },
};
</script>
